<template>
  <el-drawer
    :modal-append-to-body="false"
    custom-class="filterDrawer"
    :visible.sync="visible"
    size="100%"
    @close="close"
  >
    <template slot="title">
      <div class="df ai-c jc-c">
        <div
          style="
            color: black;
            font-size: 16px;
            font-weight: 500;
            color: #303133;
            margin-left: 40px;
            font-family: Roboto, sans-serif;
          "
        >
          Фильтры
        </div>
      </div>
    </template>
    <div class="p-l-16 p-r-16">
      <filter-section
        :option="getEntities(modalInnerType[0])"
        :type="modalInnerType[0]"
        title-section="Статус"
        @open="handleShowModalInner"
        @clickedEmit="handleRemoveFilter"
      />
      <filter-section
        :option="getEntities(modalInnerType[1])"
        :type="modalInnerType[1]"
        title-section="Дата тайм слотов"
        @open="handleShowModalInner"
        @clickedEmit="handleRemoveFilter"
      />
      <filter-section
        :type="modalInnerType[2]"
        :option="getEntities(modalInnerType[2])"
        title-section="Культура"
        @open="handleShowModalInner"
        @clickedEmit="handleRemoveFilter"
      />
      <filter-section
        :type="modalInnerType[3]"
        :option="getEntities(modalInnerType[3])"
        title-section="Поставщик из квоты"
        @open="handleShowModalInner"
        @clickedEmit="handleRemoveFilter"
      />
    </div>

    <div>
      <div class="containerMobile">
        <BottomToolbarButton
          icon=""
          title="Применить"
          @button-clicked="handleEmitFilterParams"
        />
      </div>
      <el-drawer
        :modal-append-to-body="false"
        :title="innerTitle"
        :append-to-body="true"
        :visible.sync="showModalInner"
        :show-close="false"
        custom-class="filterDrawer"
        size="100%"
      >
        <template slot="title">
          <div class="df ai-c jc-sb title-dialog-hide-btn">
            <div class="w-40 df jc-fs">
              <el-button
                style="border: none; padding: 12px 12px 12px 0px"
                @click="handleHideModalInner"
              >
                <i class="el-icon-arrow-left" style="font-size: 18px" />
              </el-button>
            </div>
            <div
              style="
                color: black;
                font-size: 16px;
                font-weight: 500;
                color: #303133;
                font-family: Roboto, sans-serif;
              "
            >
              {{ innerTitle }}
            </div>
            <div class="w-40" />
          </div>
        </template>
        <div class="p-l-16 p-r-16">
          <div v-if="modalInnerChosenType === modalInnerType[0]">
            <status-filter-modal
              :type="modalInnerType[0]"
              :entries="statusesList"
              :chosen-entities="getEntities(modalInnerType[0])"
              @handleChangeFilter="handleAddFilters"
            />
          </div>
          <div v-if="modalInnerChosenType === modalInnerType[1]">
            <date-filter-modal
              :chosen-entities="getEntities(modalInnerType[1])"
              :type="modalInnerType[1]"
              :entries="statusesList"
              @handleChangeFilter="handleAddFilters"
            />
          </div>
          <div v-if="modalInnerChosenType === modalInnerType[2]">
            <status-filter-modal
              :chosen-entities="getEntities(modalInnerType[2])"
              :type="modalInnerType[2]"
              :entries="culturesList"
              @handleChangeFilter="handleAddFilters"
            />
          </div>
          <div v-if="modalInnerChosenType === modalInnerType[3]">
            <status-filter-modal
              :chosen-entities="getEntities(modalInnerType[3])"
              :type="modalInnerType[3]"
              :entries="exportersList"
              @handleChangeFilter="handleAddFilters"
            />
          </div>
        </div>
        <!--    Секция статуса-->

        <div class="containerMobile">
          <BottomToolbarButton
            icon=""
            title="Готово"
            @button-clicked="handleHideModalInner"
          />
        </div>
      </el-drawer>
    </div>
  </el-drawer>
</template>

<script>
import { DATE_FORMAT_FULL } from '@/constants/date'
import { entriesStatuses } from '@/views/manager/data/manager'
import { getTime } from '@/core'
import BottomToolbarButton from '@/UI/form/buttons/BottomToolbarButton'
import DateFilterModal from '@/views/manager/components/mobile/components/modal/DateFilterModal'
import FilterSection from '@/views/manager/components/mobile/components/FilterSection'
import StatusFilterModal from '@/views/manager/components/mobile/components/modal/StatusFilterModal'

export default {
  components: {
    StatusFilterModal,
    FilterSection,
    DateFilterModal,
    BottomToolbarButton,
  },
  props: {
    culturesList: { type: Array, default: () => [] },
    exportersList: { type: Array, default: () => [] },
    statusesList: { type: Array, default: () => [] },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      modalInnerChosenType: '',
      modalInnerType: ['statuses', 'dates', 'cultures', 'exporters'],
      entriesStatuses,
      statuses: [],
      centerDialogVisible: false,
      showModalInner: false,
      modalInnerTitle: 'Статус',
      chosenFilter: {},
    }
  },
  computed: {
    innerTitle() {
      switch (this.modalInnerChosenType) {
        case 'statuses':
          return 'Статус'
        case 'dates':
          return 'Дата тайм слотов'
        case 'cultures':
          return 'Культура'
        case 'exporters':
          return 'Поставщик из квоты'

        default:
          return ''
      }
    },
  },
  methods: {
    handleEmitFilterParams() {
      let count = 0
      let culture = []

      this.chosenFilter?.cultures?.map(item => culture.push(item.value))
      count += culture.length
      let exporter = []

      this.chosenFilter?.exporters?.map(item => exporter.push(item.value))
      count += exporter.length

      let time_from = ''
      let time_to = ''

      this.chosenFilter?.dates?.map(item => {
        if (item.time_from) {
          count += 1
          time_from = getTime(item.time_from, DATE_FORMAT_FULL)
        }
        if (item.time_to) {
          time_to = getTime(item.time_to, DATE_FORMAT_FULL)
          count += 1
        }
      })

      let statuses = {
        not_stated: false,
        parked_far: false,
        parked_close: false,
        arrived: false,
        late: false,
        detached: false,
      }

      this.chosenFilter?.statuses?.map(item => {
        statuses[item.value] = true
        count += 1
      })
      this.$emit(
        'handleEmitFilters',
        {
          culture,
          exporter,
          time_from,
          time_to,
          statuses,
        },
        count,
      )
      this.close()
    },
    getEntities(type) {
      return this.chosenFilter[type] || []
    },
    handleAddFilters(filters, type) {
      this.chosenFilter = { ...this.chosenFilter, [type]: filters }
    },
    handleShowModalInner(type) {
      this.showModalInner = true
      this.modalInnerChosenType = type
    },
    handleHideModalInner() {
      this.showModalInner = false
      this.modalInnerChosenType = ''
    },
    close() {
      this.$emit('close')
    },
    handleRemoveFilter(filter, type) {
      let arr = []

      if (this.chosenFilter[type]) {
        arr = this.chosenFilter[type].filter(item => item.value !== filter)
        arr.length === this.chosenFilter[type].length ? (arr = []) : null
        this.chosenFilter = { ...this.chosenFilter, [type]: arr }
      }
    },
  },
}
</script>

<style lang="sass">
.filterDrawer
  .el-drawer__header
    padding: 15px

.modal-mobile-full
  .el-dialog
    border-radius: 0px!important
    background: $color-white
    z-index: 99999
    .title-dialog-hide-btn
    .el-dialog__headerbtn
      display: none!important
    &__header

      background: white!important
      border-radius: 10px 10px 0 0
      padding: 15px!important
      color: $color-white
      position: relative
      font-weight: 500
      font-feature-settings: 'tnum' on, 'lnum' on
      .header__close
        display: none
        position: absolute
        top: 56%
        right: 10px
        transform: translateY(-50%)
        transition: $transition-default
        cursor: pointer
        &:hover
          transform: translateY(-50%) scale(1.2)
    &__body
      padding: 0px 16px!important
      word-break: break-word
      height: 85%
      overflow-y: scroll
    &__footer
      width: 100%
      box-shadow: 0px -4px 4px rgba(225, 225, 225, 0.25)

    .el-form-item
      margin-bottom: 20px
    .el-form-item__label
      text-align: left
      line-height: 32px
    +to(550px)
      .el-form-item__label
        width: 100% !important
      &:before
        font-size: 0
    .el-form-item__content
      line-height: 32px
</style>
